import { getMe } from 'api/user';
import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { useMutation } from 'react-query';
import { signInWithMagicLink } from '../api/auth';
import { Bots } from 'components/lists/lists/Bots';
import { Footer } from 'components/sections/Footer';
import { getAdditionalUserInfo } from 'firebase/auth';
import { fetchBillingSubscriptions } from 'api/billing';
import { reportAnalyticsEvent } from '../utils/analytics';
import { GetServerSideProps } from 'next';
import dynamic from 'next/dynamic';

const AfterSubscriptionModal = dynamic(
  () => import('components/modals/AfterSubscriptionModal').then(({ AfterSubscriptionModal }) => AfterSubscriptionModal),
  { ssr: false }
);

const AfterCreditsPurchaseModal = dynamic(
  () => import('components/modals/AfterCreditsPurchaseModal').then(({ AfterCreditsPurchaseModal }) => AfterCreditsPurchaseModal),
  { ssr: false }
);

export const callApisRepeatedly = async () => {
  let count = 0;
  const intervalId = setInterval(async () => {
    count++;

    await fetchBillingSubscriptions();
    await getMe();

    if (count >= 4) {
      clearInterval(intervalId);
    }
  }, 3000);
};

export const getServerSideProps: GetServerSideProps = async ({ req, params }) => {
  return {
    props: { userID: req?.cookies?.userID || null }
  };
};

const HomePage = ({ userID }: { userID: string }) => {
  const { query, pathname, replace, push } = useRouter();

  const { mutate } = useMutation(signInWithMagicLink, {
    onSuccess: (userCredential) => {
      if (!userCredential) return;
      const additionalInfo = getAdditionalUserInfo(userCredential);

      if (additionalInfo?.isNewUser) {
        reportAnalyticsEvent('sign_up', { method: 'email' });
        push('/signup/success');
      } else {
        reportAnalyticsEvent('login', { method: 'email' });
        push('/', undefined, { shallow: true });
      }
    }
  });

  const closeModal = () => {
    replace({ pathname, query: {} }, undefined, { shallow: true });
    callApisRepeatedly();
  };

  useEffect(() => {
    if (query.apiKey && query.email) {
      mutate(query.email as string);
    }
  }, [query]);

  return (
    <>
      <div className="container">
        <div className="flex flex-col gap-8 md:gap-32">
          <Bots isLoggedSSR={Boolean(userID)} />
        </div>

        {query?.credits && (
          <AfterCreditsPurchaseModal onClose={closeModal} type={query?.credits as 'success' | 'cancel'} />
        )}

        {/* Cancel state is here, success is handeled on /subscriptions/success because of GA */}
        {query?.billing === 'cancel' && <AfterSubscriptionModal onClose={closeModal} type={query?.billing} />}
      </div>
      <Footer />
    </>
  );
};

export default HomePage;
